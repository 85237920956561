import React, { useContext, useState } from "react";
import ReactECharts from "./ReactECharts";
import { regression } from "echarts-stat";
import Icon from "../Icon";
import { AppContext, ChartsContext, MapContext } from "../context";
import { SubHeader, formatNumber } from "../helpers";

/**
 * @function - calculate R-squared for linear regression
 * @param {array} data 
 * @returns {number}
 */
function calculateRsquared( data ){
  try {
    if( !data ) {
      throw "No data";
    }

    let n = data?.length;
    if( !n ) {
      throw "Empty data array";
    }

    const { expression } = regression('linear', data, {} );
    if( !expression ) {
      throw "Regression failed";
    }

    const elements = expression.match( /^\s*y\s*=\s*([\-\d\.x]+)\s*([+\-])\s*([\-\d\.x]+)\s*$/ );

    if( !elements || elements?.length !== 4 ) {
      throw "Failed to parse expression: " + expression;
    }

    const offset = Number( /x/.test( elements[1] ) ? elements[3] : elements[1] ),
          xVar = Number( ( /x/.test( elements[1] ) ? elements[1] : elements[3] ).replace( /x/, '' ) ),
          action = elements[2];

    // R2 = 1 - ( SUM (y - ŷ)^2 ) / ( SUM (y - ȳ)^2 )
    
    // calculate SUM of (y - ŷ)^2
    let sumY = 0,
        sumY_yHat_2 = 0;
    for( let i = 0; i < n; i++ ) {
      const [x,y] = data[i];

      sumY += y;

      let yHat = x * xVar;

      if( action === '+' ) {
        yHat += offset;
      }
      else {
        yHat -= offset;
      }

      sumY_yHat_2 += Math.pow( (y - yHat), 2);
    }

    const yBar = sumY / n;
    let sumY_yBar_2 = 0;
    for( let i = 0; i < n; i++ ) {
      const [x,y] = data[i];

      sumY_yBar_2 += Math.pow( (y - yBar), 2 );
    }

    const R2 = 1 - sumY_yHat_2 / sumY_yBar_2;

    return Math.round( R2 * 100 ) / 100;
  }
  catch( error ){
    console.log( error );
    return 0;
  };
} // calculateRsquared


const Chart = ({ width, chart }) => {
  const {
    allTableFields,
    statsData,
    fields,
    getFips,
    lockedFips
   } = useContext( AppContext )
  
  const {
    dispatch: mapDispatch,
    fips: mapFips
  } = useContext( MapContext );

  const {
    dispatch
  } = useContext( ChartsContext );

  const [version,setVersion] = useState(1);
  const { series } = chart;

  /**
   * @var {array} dataSeries - transformed data for chart
   * @var {array} dataSeriesFipsCodes - list of series fips code
   * @var {array} lockedFipsSeries - list of data for locked fips codes, so we can show them with different zlevel
   */
  const dataSeries = [],
        dataSeriesFipsCodes = [],
        lockedFipsSeries = [];

  // transform raw data into data array for chart
  // [ [[x,y],[x,y],[x,y]], ... ]
  series?.forEach( ( s,si ) => {
    const data = {};

    statsData?.stats?.filter( set => s.includes( set.field_name) )
    .forEach( set => {
      const fips = set.fips_code;

      if( typeof data[ fips ] === "undefined" ) {
        data[ fips ] = [ 0, 0 ];
      }

      const field = fields.find( f => f.name === set.field_name );

      data[ fips ][ s.indexOf( set.field_name ) ] = ( field.unit === '%' ? set.value * 100 : set.value );
    });

    s.forEach( (fielName,fieldIndex) => {
      if( !fielName.match( /^_idx/ || !statsData?.idx || typeof statsData?.idx[ fielName ] === "undefined" ) ) {
        return;
      }

      for( let fips in statsData?.idx[ fielName ]?.data ) {
        if( typeof data[ fips ] === "undefined" ) {
          data[ fips ] = [ 0, 0 ];
        }
  
        data[ fips ][ fieldIndex ] = statsData?.idx[ fielName ]?.data[fips];
      }      
    });
    
    const keys = Object.keys( data ),
          vals = Object.values( data );

    dataSeriesFipsCodes.push( keys );
    dataSeries.push( vals );


    const lockedFipsData = lockedFips.map( f => {
      const index = keys.indexOf( String(f) );

      if( index === -1 ) {
        return [0,0,null];
      }

      return [ ...vals[ index ], index ];
    });

    if( mapFips ) {
      const index = keys.indexOf( String( mapFips ) );

      if( index !== -1 ) {
        lockedFipsData.push( [ ...vals[ index ], index ] );
      }
    }

    lockedFipsSeries.push( lockedFipsData );
  });

  if( !dataSeries?.length || !dataSeries[0].length ) {
    return <></>
  }

  /**
   * @function - formats information for tooltip, that is shown on mouse over
   * @param {object} params - information on data item
   * @returns {string} - html of the tooltip
   */
  function tooltipFormatter(params){
    const { seriesIndex, data, value, dataIndex } = params;

    const [xName,yName] = series[ 0 ];

    const xField = fields.find( f => f.name === xName ),
          yField = fields.find( f => f.name === yName );


    const code = data.length === 3 ? dataSeriesFipsCodes[ 0 ][ data[2] ] : dataSeriesFipsCodes[ 0 ][ dataIndex ];

    const fips = getFips( code );

    if( fips && mapFips !== fips.code ) {
      mapDispatch({ type: 'set-fips', value: fips.code });
    }

    return `<strong>${fips.label}</strong><br />
            <hr />
            ${xField.label}: <strong>${formatNumber( data[0], xField?.unit )}</strong><br />
            ${yField.label}: <strong>${formatNumber( data[1], yField?.unit )}</strong>`
  } // end of tooltipFormatter 


  function minCalculator(value){
    if( fields.find( f => f.name === chart?.series[0][0] )?.unit === '%' ) {
      const min = Math.floor(value.min) - 0.5;
      return min > 0 ? min : 0;
    }

    return Math.floor( value.min * 0.9 );
  }

  function maxCalculator(value){
    if( fields.find( f => f.name === chart?.series[0][0] )?.unit === '%' ) {
      return Math.ceil( value.max ) + 0.5;
    }

    return Math.ceil( value.max * 1.05 );
  }

  const axisConfig = {
    nameLocation: 'middle',
    nameGap: 29,
    axisTick: {
      show: false
    },
    axisLabel: {
      show: true,
      fontSize: 10,
      margin: 2,
    },
    min: minCalculator,
    max: maxCalculator,
  };

  /**
   * @var {object} - default series entry configuration
   */
  const scatterConfig = {
    name: 'scatter',
    type: 'scatter',
    itemStyle: {
      borderWidth: 1,
      borderColor: '#5d5db2', 
      color: '#9797df'
    },
    tooltip: {
      formatter: tooltipFormatter
    }
  };

  
  const chartOption = {
    textStyle: {
      fontSize: 10,
      fontFamily: 'Plus Jakarta Sans, sans-serif'
    },
    xAxis: { 
      ...axisConfig, 
      name: fields.find( f => f.name === chart?.series[0][0] )?.label,
      ...{ 
        axisLabel: {
          formatter: (value) => formatNumber( value, fields.find( f => f.name === chart?.series[0][0] )?.unit )
        }
      }
    },
    yAxis: { 
      ...axisConfig, 
      name: fields.find( f => f.name === chart?.series[0][1] )?.label,
      ...{
        axisLabel: {
          formatter: (value) => formatNumber( value, fields.find( f => f.name === chart?.series[0][1] )?.unit )
        }
      }
    },
    dataset: [
      { source: dataSeries?.length ? dataSeries[0] : [] },
      { transform: { type: 'ecStat:regression' } },
      { source: lockedFipsSeries?.length ? lockedFipsSeries[0] : [] }
    ],
    tooltip: {},
    series: [ 
      scatterConfig,
      {
        name: 'line',
        type: 'line',
        datasetIndex: 1,
        symbolSize: 0.1,
        symbol: 'circle',
        label: { show: false, fontSize: 12 },
        labelLayout: { dx: -20 },
        encode: { label: 2, tooltip: 1 },
        clip: false
      },
      { ...scatterConfig, 
        ...{
          name: "Locked FIPS",
          datasetIndex: 2,
          itemStyle: {
            color: '#173276'  
          },
          zlevel: 1,
          animation: false
        }  
      }      
    ],
    dataZoom: [{ type: 'inside' }],
  };

  const R2 = dataSeries?.length ? calculateRsquared( dataSeries[0] ) : 0;

  return (
    <div>
      
      <div className="pl-11">
        <div className="flex justify-between items-center">
          <SubHeader>Scatter</SubHeader>

          <button onClick={ () => dispatch({ type: 'remove', chart }) }
                  className="text-red-800 hover:text-red-600">Remove</button>
        </div>

        <div className="flex gap-4 text-xs leading-4 font-semibold">
          <div className="flex flex-col gap-4 items-start">
            { 
              chart?.series?.map( (s,i) => (
                <div key={i} className="flex gap-4 flex-wrap items-center">
                  <div className="flex gap-2 items-center">
                    <span>x-axis:</span>
                    <select className="w-32" 
                      value={ series[i][0] }
                      onChange={ (e) => {
                        const list = JSON.parse( JSON.stringify( series ) );
                        list[ i ][ 0 ] = e.target.value;

                        dispatch( { type: 'update', chart, prop: 'series', value: list });
                        setVersion( version + 1 );
                      }}>
                      <option>Select field</option>

                      { 
                        allTableFields.filter( f => {
                          const name = typeof f === 'object' ? f.name : f;
                          return s[1] !== name && !['fipsCode', 'fipsLabel'].includes( name );
                        }).map( f => {
                          const name = typeof f === 'object' ? f.name : f;
                          return <option x={name} key={ name } value={ name }>
                                  { fields?.find( f => f.name === name )?.label }
                                </option>
                        }) 
                      }          
                    </select>
                  </div>

                  <div className="flex gap-2 items-center">
                    <span>y-axis:</span>
                    <select className="w-32" 
                      value={ series[i][1] }                  
                      onChange={ (e) => {
                        const list = JSON.parse( JSON.stringify( series ) );
                        list[ i ][ 1 ] = e.target.value;

                        dispatch( { type: 'update', chart, prop: 'series', value: list });
                        setVersion( version + 1 );
                      }}>
                      <option>Select field</option>

                      { 
                        allTableFields.filter( f => {
                          const name = typeof f === 'object' ? f.name : f;
                          return s[0] !== name && !['fipsCode', 'fipsLabel'].includes( name );
                        })                  
                      .map( f => {
                          const name = typeof f === 'object' ? f.name : f;
                          return <option key={ name } value={ name }>
                                    { fields?.find( f => f.name === name )?.label }
                                  </option>
                        }) 
                      }          
                    </select>
                  </div>

                  <div className="font-bold">
                    R<sup>2</sup> = <span className="inline-block px-1">{ R2 }</span>
                  </div>

                  <button className="hidden"
                    onClick={ () => {
                      const list = JSON.parse( JSON.stringify( series) );
                      list.splice( i, 1 );

                      dispatch( { type: 'update', chart, prop: 'series', value: list });
                      setVersion( version + 1 );
                    }}>
                    <Icon name="trash" />
                  </button>
                </div>
              )) 
            }

            <button 
              className="hidden border border-slate-500 rounded px-3 py-1 hover:bg-slate-100"
              onClick={ (e) => {
                const list = JSON.parse( JSON.stringify( series) );
                list.push( ["", ""] );

                dispatch( { type: 'update', chart, prop: 'series', value: list });
                setVersion( version + 1 );
              }}>Add dataset
            </button>
          </div>
        </div>
      </div>

      <ReactECharts
        width={ width }
        style={{
          height: '50vh',
          width: '100%'
        }}
        option={ chartOption }
        settings={{ }}
        loading={false}
        theme=""
        reload={ version }
      />
    </div>
  );
} // 

export default Chart;