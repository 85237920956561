/**
 * @function mapReducer
 * @param {object} state 
  * @prop {string} field - what field is visualized on the map
  * @prop {number} reload 
  * @prop {boolean} recenter
  * @prop {Array.<number|string>} fips - list of FIPS codes, shown on the map
  * @prop {boolean} mask - whether to mask location or not
  * @prop {string} bounds -  coordinates of map bounds, retrieved from map, used in table data loaded and in current state hash
  * @prop {number} zoom - current map zoom
  * 
 * @param {object} action
  * @prop {string} type
  * @prop {string} prop
  * @prop {string} value
  * 
 * @returns {object} new state
*/
export function mapReducer( state, action ){
  
  const newState = JSON.parse( JSON.stringify( state ) );

  function update( prop, value ){
    newState[ prop ] = value;
    // localStorage?.setItem(  );
  } 
  
  if( action.type === 'set' 
      && action?.prop
      && typeof action.value !== "undefined"
      && typeof newState[ action.prop ] !== "undefined" ) {

    update( action.prop, action.value );

    return newState;
  }
  else if ( /^set-(.+)$/.test(action.type) ) {
    const matched = action.type.match( /^set-(.+)$/ );

    if( matched 
        && typeof action.value !== "undefined"  
        && typeof newState[ matched[1] ] !== "undefined" ) {
      
      update( matched[1], action.value );          

      return newState;
    }
  }

  return newState;
}


export function chartReducer( charts, action ) {
  const defaults = {
    histogram: { 
      method: 'squareRoot' 
    } 
  };

  function _setCharts(){
    localStorage?.setItem( 'charts', JSON.stringify(newCharts) );

    return newCharts;
  }

  function add( type ){
    const newChart = { type };
    switch( type ) {
      case 'histogram':
        newChart.field = "";
        newChart.method = defaults?.histogram?.method;
        break;
      case 'scatter': 
        newChart.series = [[ "", "population" ]]
        break;
      default:
    }
    newCharts.push( newChart );
    return _setCharts( newCharts );
  }

  function remove( chart ) {
    newCharts.splice( charts.indexOf(chart), 1 );
    return _setCharts( newCharts );
  }

  function update( chart, prop, value ) {
    newCharts[ charts.indexOf( chart ) ][ prop ] = value;
    return _setCharts( newCharts );
  }

  const newCharts = JSON.parse( JSON.stringify( charts || '[]' ) );

  switch( action.type ) {
    case "add":
      return add( action.chartType );

    case "remove":
      return remove( action.chart );
    
    case "update":
      return update( action.chart, action.prop, action.value );
    
    case "replace":
      return JSON.parse( JSON.stringify( action.value ) );
  }

}