function applyStateExtremes( choro, fieldInfo, data ){
  const { name: field } = fieldInfo;

  if( !field ) {
    return choro;
  }

  if( !fieldInfo?.id  ) {
    console.log( "Field is invalid", fieldInfo );
    return choro;
  }

  if( !data || (!data.extremes && !data.idx )) {
    // console.log( "Extremes not ready" );
    return choro;
  }

  const extremes = data?.extremes[fieldInfo.id] ?? data?.idx[fieldInfo.name];
  if( !extremes ) {
    console.log( "No extremes for the field", fieldInfo );
    return choro;
  }

  const lastIdx = choro.blocks.length - 1;
  if( fieldInfo?.unit === '%' ) {
    let min = extremes.min * 100,
        max = extremes.max * 100;
    choro.blocks[0].min = Number.parseFloat( min ).toFixed(2);
    choro.blocks[ lastIdx ].max = Number.parseFloat( choro.blocks[ lastIdx ].max < max ? max : choro.blocks[ lastIdx ].max ).toFixed(2);
  }
  else {
    choro.blocks[0].min = Number.isInteger( extremes.min ) ? extremes.min : Number.parseFloat( extremes.min ).toFixed(2);

    if( choro.blocks[ lastIdx ].max > 0 && choro.blocks[ lastIdx ].max < extremes.max ) {
      choro.blocks[ lastIdx ].max = Number.isInteger( extremes.max ) ? extremes.max : Number.parseFloat( extremes.max ).toFixed(2);
    }  
  }
  
  return choro;
} // applyStateExtremes

export function recalculateCategories( choro = null, fieldInfo, data ){
  if( !choro ) {
    return;
  }

  const methods = {
    quantiles: quantilesMethod,
    equalIntervals: equalIntervalsMethod // methods 
  }

  let method = 'quantiles';
  if( choro.method && methods[ choro.method ] ) {
    method = choro.method;
  }
  
  const categories = methods[ method ]( choro, fieldInfo, data ); 

  return applyStateExtremes( categories, fieldInfo, data );
} // recalculateCategories


export function quantilesMethod( choro, fieldInfo, data  ){
  const { name: field } = fieldInfo;
  const entries = Object.values( data.entries );

  entries.sort( (a,b) => {
    if( isNaN(+a[field]?.value) ) {
      return -1;
    }

    if( isNaN(+b[field]?.value)) {
      return 1;
    }

    return (+a[field]?.value) - (+b[field]?.value);
  });

  const categories = choro?.blocks?.length ?? 5,
        perCategory = Math.ceil( entries.length / categories );
  
  let idx = 0;
  let min = Math.min( ...( Object.values( data.entries ).filter( e => e[field] && !isNaN( +e[field].value ) ).map( e => +( e[field]?.value ) ) ) );

  for( let i = 0; i < categories; i++ ) {
    let max = min;
    for( let j = 0; j < perCategory; j++ ) {
      if( !entries[ idx + j ] ) {
        continue;
      }

      let val = entries[ idx + j ][ field ]?.value ?? false;
      if( fieldInfo?.unit === '%' ) {
        val = Math.round( val * 10000 ) / 100;
      }

      if( false === val ) {
        continue;
      }
      
      if( val > max ) {
        max = val;
      }
    }
    
    idx += perCategory;

    if( typeof choro.blocks[i] === 'undefined' ) {
      choro.blocks[i] = {};
    }

    choro.blocks[i].min = Number.isInteger( min ) ? min : Number.parseFloat( min ).toFixed(2);
    choro.blocks[i].max = Number.isInteger( max ) ? max : Number.parseFloat( max ).toFixed(2);

    min = max + ( +fieldInfo?.step ?? 1 );
  }
  
  return choro;
} // 

export function equalIntervalsMethod( choro, fieldInfo, data ){
  const { name: field } = fieldInfo;

  const entries = Object.values( data.entries ).map( e => +( e[field]?.value ?? 0 ) ); 

  const max = Math.max( ...(entries.filter( e => e !== null ) ) );

  const categories = choro?.blocks?.length ?? 5,
        step = max / categories; 

  for( let i = 0; i < categories; i++ ) {
    if( typeof choro.blocks[i] === 'undefined' ) {
      choro.blocks[i] = {};
    }
    choro.blocks[i].min = Math.round( step * i * 10 ) / 10;
    choro.blocks[i].max = Math.round( step * ( i + 1 ) * 10 ) / 10 - ( +fieldInfo?.step ?? 1 );
  }

  return choro;
} // 