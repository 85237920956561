import { useEffect, useState } from "react";

import Icon from "../Icon";

const ColumnConfig = ({
  field,
  sort,
  setSort,
  remove
}) => {
  
  const [open,setOpen] = useState( false );

  const isInSort = field && sort && sort.find( s => s.field === field.id );

  return (
    <div className="relative">
      <button onClick={ () => setOpen( true ) } className="px-1">
        <Icon name="ellipsis-vertical" />
      </button>

      { open && (
        <div className="absolute top-full right-0 z-50 bg-white shadow-sm shadow-gray-600 border border-gray-400">
          <button className="absolute right-0 top-0 translate-x-1/2 -translate-y-1/2 leading-none p-1
                              border border-gray-200 bg-white rounded-full
                            hover:bg-gray-400
                            w-6 h-6"
            onClick={() => setOpen(false) }
            >
            <Icon name="xmark" />
          </button>

          <div className="flex flex-col gap-2 p-2">
            <button onClick={ () => { setSort( 'ASC' ); setOpen(false) } }
              className="w-full flex gap-2 items-center hover:bg-slate-100"
              >
              <Icon name="arrow-down-short-wide" className={ isInSort && isInSort.sort == "ASC" ? "bg-gray-400" : '' } />
              <span className="whitespace-nowrap">Sort ASC</span>
            </button>

            <button onClick={ () => { setSort( 'DESC' ); setOpen(false) } }
              className="w-full flex gap-2 items-center hover:bg-slate-100">
              <Icon name="arrow-down-wide-short" className={ isInSort && isInSort.sort == "DESC" ? "bg-gray-400" : '' } />
              <span className="whitespace-nowrap">Sort DESC</span>
            </button>

            <button onClick={ () => { remove(); setOpen(false) } }
              className="w-full flex gap-2 items-center hover:bg-slate-100">
              <Icon name="trash-can" />
              <span>Remove</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );

}


export default ColumnConfig;