import React, { useContext, useEffect, useState } from "react";
import ReactECharts from "./ReactECharts";
import { histogram } from "echarts-stat";
import { AppContext, ChartsContext, MapContext, } from "../context";
import { SubHeader, formatNumber } from "../helpers";

const HistogramChart = ({ chart, width }) => {
  const {
    statsData,
    fields,
    allTableFields
   } = useContext( AppContext );

  const { field: mapField } = useContext( MapContext );

  const {
    defaults,
    dispatch
  } = useContext( ChartsContext );

  const [version,setVersion] = useState(1);

  let barChartDataSet = [];
  if( statsData?.idx && chart.field.match( /^_idx/ ) && typeof statsData?.idx[ chart.field ]?.data !== "undefined" ) {
    barChartDataSet = Object.values( statsData?.idx[ chart.field ]?.data ).map( v => [v] );
  }
  else {
    barChartDataSet = statsData?.stats?.filter( s => s.field_name === chart.field )
      .map( s => {
        if( fields.find( f => f.name === chart.field )?.unit === '%' ) {
          return [s.value * 100];
        }

        return [s.value];
    }) ?? [];
  }

  const histogramData = barChartDataSet?.length ? histogram( barChartDataSet ) : null;

  const chartOption = {
    textStyle: {
      fontSize: 10,
      fontFamily: 'Plus Jakarta Sans, sans-serif'
    },
    xAxis: {
      type: 'category',
      scale: true,
      axisTick: {
        show: true,
      },
      axisLabel: {
        show: true,
        fontSize: 10,
        margin: 5,
        formatter: function(value, index){
          
          const bin = histogramData?.bins[ index ];
          const unit = fields.find(f => f.name === chart.field )?.unit;
          return formatNumber( bin?.x0, unit ) + ' - ' + formatNumber( bin?.x1, unit );
        },
      },    
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: true
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: true,
        fontSize: 10,
        margin: 5,
        formatter: function(value){
          return formatNumber( value, null )
        }        
      },
      min: function(value){
        return Math.floor( value.min*0.9 );
      },
      max: function(value){
        return Math.ceil( value.max*1.1 );
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      /*
      formatter: function( ){

      }*/
    },
    dataset: [
      {
        source: barChartDataSet || []
      },
      {
        transform: {
          type: 'ecStat:histogram',
          config: {
            method: chart.method
          }
        }
      }
    ],
    series: [{
      name: fields.find(f => f.name === chart.field )?.label,
      datasetIndex: 1,
      type: 'bar',
      barWidth: '93%',
      color: '#1a431a',
      label: { 
        show: false,
        position: 'top'
      },
    }],
    
    dataZoom: [
      {
        type: 'inside'
      }
    ],
  };

  useEffect( () => {
    setVersion( version + 1 );
  }, [chart, statsData]);

  const fieldsAvailable = allTableFields.filter(f => !['fipsLabel','fipsCode'].includes(typeof f === 'object' ? f.name : f ))
  .map(f => {
    const name = typeof f === 'object' ? f.name : f,
          label = fields.find( f => f.name === name )?.label ?? name;
    return { name, label };
  });
 
  return (
    <div>
      <div className="pl-11">
        <div className="flex justify-between items-center">
          <SubHeader>Histogram</SubHeader>

          <button onClick={ () => dispatch({ type: 'remove', chart }) }
            className="text-red-800 hover:text-red-600">Remove</button>
        </div>

        <div className="flex flex-col gap-2 text-xs leading-4 font-semibold">
          <div className="flex flex-wrap gap-x-6 gap-y-2 items-center">
            <div className="flex flex-wrap gap-2 items-center">
              <div>Field:</div>

              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center">
                  <select className="w-32"
                        value={ chart.field }
                        onChange={ (event) => {
                        const value = event?.target?.value;

                        dispatch({ type: 'update', chart, prop: 'field', value: value || mapField });
                      } }>
                    <option value="">Select Field</option>
                    { fieldsAvailable.map( f => <option key={f.name} value={f.name}>{f.label}</option> ) }
                  </select>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap gap-2 items-center">
              <div>Method:</div>

              <div className="">
                <select className="w-32"
                  value={ chart.method }
                  onChange={ (event) => {
                    let value = event?.target?.value;
                    if( !value ) {
                      value = defaults?.histogram?.method;
                    }
                    dispatch({ type: 'update', chart, prop: 'method', value: value });
                    setVersion( version + 1 );
                  }}
                  >
                  <option value="">Select method</option>
                  <option value="squareRoot">Square-root choice</option>
                  <option value="scott">Scott's normal reference rule</option>
                  <option value="freedmanDiaconis">Freedman–Diaconis rule</option>
                  <option value="sturges">Sturges's formula</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>


      { barChartDataSet?.length > 0 && 
        <ReactECharts
          width={ width }
          style={{
            height: '40vh',
            width: '100%'
          }}
          option={ chartOption }
          settings={{
          }}
          loading={false}
          theme=""
          reload={ version }
        />
      }
    </div>
  );
} // 

export default HistogramChart;