import { useContext, useState } from "react";
import { AppContext, MapContext } from "../context";

import * as XLSX from "xlsx";

import DataTable from "./DataTable";
import DataSort from "./DataSort";
import DataFilter from "./DataFilter";
import Icon from "../Icon";

import { Header } from "../helpers";

const DataTableContainer = ({ width, show }) => {
  const {
    defaultFields,
    columns,
    tableSort,

    counties,
    places,

    location,
    level,

    fields,
    setTableSort,
    setTableFilter,
    setPopulationThreshold,
  } = useContext( AppContext );

  const { dispatch: mapDispatch } = useContext( MapContext );  

    // indicate table export process
    const [doingTableExport,setDoingTableExport] = useState( false );


  function exportTable(){
    setDoingTableExport( true );


    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    let sheetName = "mcje";
    let filename = "mcje-";
    if( location ) {
      let locationName = [...counties,...places].find( l => l.code == location )?.label ?? location;
      locationName = locationName.replace( /[\.,\s]/g, '-' );

      sheetName += '-' + locationName;

      filename += locationName + "-";
    }

    if( level ) {
      filename += `by-${level}-`;
    }

    filename += (new Date).toISOString();

    // Acquire Data (reference to the HTML table)
    const tbl = document.querySelector(".data-table");

    // Extract Data (create a workbook object from the table)
    const workbook = XLSX.utils.table_to_book(tbl, { sheet: sheetName });
    // const workbook = XLSX.utils.table_to_book(tbl);

    /*
    // Process Data (add a new row)
    const ws = workbook.Sheets[ sheetName ];
    const cols = tbl.querySelector('tbody tr').children.length;

    XLSX.utils.sheet_add_aoa(ws, [
      [{ t: 's', v: "Created "+new Date().toISOString() }]
    ], { origin: { c: cols, r: 1 } });
    */

    XLSX.writeFile(workbook, `${ filename }.xlsx`);

    setDoingTableExport( false );
  } // exportTable

  return (
    <div className="container table-container" 
      style={{ width: `calc( ${width}% - 1rem - 0.5px )`, display: !show && 'none' }}>

      <div className="--menu flex justify-between items-center mb-5">
        <Header>Data Table</Header>

        <div className="flex gap-6 text-xs leading-4 font-semibold">
          
          { false && <DataSort 
            allFields={ fields }
            fields={ [...defaultFields, ...columns] }
            sort={ tableSort }
            setSort={ ( sort ) => {
              localStorage?.setItem('dataSort', JSON.stringify( sort ) )
              setTableSort( sort ) 
              }
            } 
            /> }

          <DataFilter
            allFields={ fields }
            fields={ [...defaultFields, ...columns] }
            onSet={ ( type, fltr ) => {
                if( type == 'select' ) {
                  setPopulationThreshold( null );
                  setTableFilter( fltr ) 
                }
                else {
                  setPopulationThreshold( fltr );
                  setTableFilter( null );
                }
              }
            } />

          { false && <button className="flex gap-2 items-center"
            onClick={ () => exportTable() }
            >
            { doingTableExport ? <span className="animate-spin"><Icon name='hourglass-half' /></span> : <Icon name='upload' /> }
            <span>Export</span>
          </button> }
        </div>
      </div>

      <div onMouseLeave={() => {
        mapDispatch({ type: 'set-fips', value: null });
        }}>
        { fields.length && 
          <DataTable />
        }
      </div>
    </div>
    );
}

export default DataTableContainer;