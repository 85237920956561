import { useContext } from "react";
import AppContext from "./context";
import Icon from "./Icon";
import { cn } from "./helpers";

/*
[
    "crisis_shelter_provision",
    "acute_family_interventions",
    "school_disciplinary_interruptions",
    "economic_distress_relief",
    "safety_conditions",
    "educational_conditions",
    "physical_environment"
]
*/

const Sidebar = ( { list, idx = 0, prefix = "" } ) => {
  const {
    defaultFields,
    columns,
    indexVariables,
    toggleColumn,

    idxVar,
    setIdxVar,
    sidebarCategoriesOpen,
    toggleSidebarCategory,
    } = useContext( AppContext );

  function addIdxVar( key ) {
    // console.log( 'fn addIdxVar', key );
    const newIdxVar = JSON.parse( JSON.stringify(idxVar) );
    if( newIdxVar.vars.length === 10 ) {
      alert("Current variables limit of 10 has been reached");
      return;
    }

    newIdxVar.vars.push({
      name: key,
      dir: 'high',
      weight: 1
    });

    setIdxVar( newIdxVar );
  }

  const parent = prefix.replace(/-$/,'');
  const defaults = [...defaultFields.map( f => typeof f === 'object' ? f.name : f )];

  return (
    <ul data-parent={ parent } className={ cn({
      hidden: idx && !sidebarCategoriesOpen.includes( parent ),
      'pl-4 mt-2': !!idx,
      'flex flex-col gap-2': true,
      'top-level': !idx
    } ) }>
      { list.map( (level) => {
          const itemIcon = level.icon;
          const key = level.key.replace(/^cat_/,''),
                isOpen = sidebarCategoriesOpen.includes( key );

          let isFinal = !( level?.children?.length ) && idx;

          if( !isFinal ) {
            //  || level?.children?.reduce((prev,cur)=> prev + cur?.fields ? 1 : 0, 0) == 0
            // console.log( key, level );
          }

          if( level.parent === 999999 ) {
            const isActive = columns.includes( key ) || defaults.includes( key );

            // display custom index var with pencil
            return (
              <li className={ cn({
                  'is-open': isOpen,
                  'top-level-item': !idx
                  }) 
                }
                data-key={ key }
                key={ key }>

                <div className="flex justify-between items-center gap-2 pr-2">

                  <button onClick={ () => isFinal ? ( idxVar ? addIdxVar( key ) : toggleColumn( key, level ) ) : toggleSidebarCategory( key ) }
                    className={ cn({
                      'active': columns.includes( key ) || defaults.includes(key),
                      'flex gap-2 items-center text-left': true
                    } ) }>
                    { itemIcon && <Icon name={ itemIcon } /> }
                    { level.label }
                  </button>
                
                  
                  <span className="flex gap-2">
                    <button onClick={ () => {
                          toggleColumn( key.replace( /^(cat_)+/, '' ), level ) 
                        }
                      }
                      key={ key.replace( /^(cat_)+/, '' ) }
                      title={ "Add to table" }
                      className={ cn({
                        'nav-btn-inactive': !isActive,
                        'nav-btn-active': isActive,
                        'nav-btn aspect-square': true
                      } ) }>
                      <Icon name="hashtag" />
                    </button>                    

                    <button className="nav-btn bg-none hover:bg-gray-600 hover:text-white"
                      onClick={ () => {
                          const newIdxVar = indexVariables.find( v => v.name === key.replace('cat_','') );
                          // console.log( indexVariables, key )
                          setIdxVar(newIdxVar) 
                        }
                      }>
                        <Icon name="pencil" />
                    </button>
                  </span>
                  
                </div>
              </li>
            );
          }
          else if ( ( idx < 3 && !level.final ) || isFinal ) {
            // display category
            return (
              <li className={ cn({
                  'is-open': isOpen,
                  'top-level-item': !idx,
                  'has-children': !isFinal,
                  'disabled': isFinal && typeof level.fields !== 'undefined'
                  }) 
                }
                data-key={ key }
                key={ key }>

                <button onClick={ () => {
                    if( isFinal ) {
                      if( idxVar ) {
                        addIdxVar( key );
                      } 
                      else {
                        if( typeof level.fields === 'undefined' ) {
                          // it's a field
                          toggleColumn( key, level );
                        }
                        else {
                          // it's an empty category
                          // ignore
                        }
                      }
                    }
                    else {
                      toggleSidebarCategory( key );
                    }
                  }}
                  className={ cn({
                    'active': columns.includes( key ) || defaults.includes( key ),
                    'flex gap-2 items-center': true
                  } ) }>
                  { itemIcon && <Icon name={ itemIcon } /> }
                  { level.label }
                </button>

                { level?.children?.length && <Sidebar list={ level.children } idx={ idx + 1} prefix={ key + '-' } /> }
              </li>
            );
          }
          else if( !isFinal ) {
            // display variable
            return (
              <li className={ cn({
                  'is-open': isOpen,
                  'top-level-item': !idx
                  }) 
                }
                data-key={ key }
                key={ key }>
                <div className="flex justify-between items-center gap-2 pr-2">
                  <span className="text-black/70">{ level.label }</span>

                  <span className="flex gap-2 items-center">
                    { level.children && level.children.length && level.children.map( (c) => {
                      if( c.key.match( /#all$/ ) ) {
                        return null;
                      }

                      let bIcon;
                      if( c.icon ) {
                        bIcon = <Icon name={ c.icon } />
                      }
                      else {
                        if( c.unit ) {
                          switch( c.unit ) {
                            case '$': bIcon = <Icon name="dollar-sign" />; break;
                            case '%': bIcon = <Icon name="percent" />; break;
                            default:
                              bIcon = <Icon name="hashtag" />;
                          }
                        }
                        else if( c.key.match( /_1k$/ ) ) {
                          bIcon = <Icon name="divide" />
                        }
                        else if( c.key.match( /_exp$/ ) ) {
                          bIcon = <Icon name="dollar-sign" />
                        }
                        else {
                          bIcon = <Icon name="hashtag" />;
                        }
                      }

                      const isActive = columns.includes( c.key ) || defaults.includes( key );

                      return (
                        <button onClick={ () => {
                          idxVar ? addIdxVar( c.key ) : toggleColumn( c.key, c )
                        }}
                          key={ c.key }
                          title={ c.label }
                          className={ cn({
                            'nav-btn-inactive': !isActive,
                            'nav-btn-active': isActive,
                            'nav-btn aspect-square': true
                          } ) }>
                          { bIcon }
                        </button>
                      )   
                    })}
                  </span>
                </div>
              </li>
            );
          }
          
          return <li key={ key }></li>
        })
      }
    </ul>
  )
} // 

export default Sidebar;