import { useContext, useState, useEffect  } from "react";
import { AppContext, MapContext } from "./context";

import Icon from "./Icon";

export function Header( { children } ) {
  
  return (
    <div>
      <span className="text-lg leading-6 font-semibold">{ children }</span>
    </div>
  );
}

export function SubHeader( { children } ) {
  return (
    <div className="mb-2">
      <span className="text-base leading-4 font-semibold">{ children }</span>
    </div>
  );
}

export function ToggleIcon( { state } ) {
  return (
    <span className={ 
      `border border-gray-400 rounded-full p-0.5 w-8 text-xs leading-0 flex ` + 
      ( state ? 'bg-green-600 text-green-800 justify-end' : 'bg-gray-200 text-gray-800 justify-start' ) 
      }>
      <Icon name={`circle-` + ( state ? 'check' : 'xmark' ) } />
    </span>
  );
}

// helper function to construct className
export function cn(){
  const names = [];

  for( let i = 0; i < arguments.length; i++ ) {
    const obj = arguments[i];

    switch( typeof obj ) {
      case 'object': 
        if( Array.isArray(obj) ) {
          for( let i = 0; i < obj.length; i++ ) {
            names.push( cn( obj[i]) );
          }
        }
        else {
          for( let name in obj ) {
            if( obj[name] ) {
              names.push( name );
            }
          }
        }
        break;
      case 'string':
        names.push( obj );
        break;
      default:
    }
  }
  

  return names.join(' ');
} // cn

export function formatNumber( number, unit){

  let value = number;
  switch( unit ) {
    case "%":
      value = Math.round( number * 10 ) / 10;
      return `${value}%`;      
   
    default:
      let symbol = '';
      if( number > 100000000 ) {
        symbol = 'B';
        value = Math.round( number / 100000000 ) / 10;
      }
      else if ( number > 1000000 ) {
        symbol = 'M';
        value = Math.round( number / 100000 ) / 10;
      }
      else if ( number > 1000 ) {
        symbol = 'K';
        value = Math.round( number / 100 ) / 10;
      }

      if( unit ) {
        return `${unit} ${value}${symbol}`;
      }

      return `${value}${symbol}`;

  }

}

export function makeFieldInfo( field, fields ) {
  const info = ( field && fields ) ? fields.find( f => f.name === field ) : { step: 0.01 };

  if( info ) {
    info.step = +info.step;

    if( !info.step ) {
      info.step = 1;
    }
  }

  return info;
}

export function useFieldInfo(){
  const { field } = useContext( MapContext );
  const { fields } = useContext( AppContext );
  const [info,setInfo] = useState( makeFieldInfo( field, fields ) );

  useEffect( () => {
    const fieldInfo = makeFieldInfo( field, fields );
    setInfo( fieldInfo );
  }, [field,fields]);

  return info;
}


const Helpers = {
  Header,
  SubHeader,
  ToggleIcon,
  cn,
  formatNumber,
  useFieldInfo
};

export default Helpers;