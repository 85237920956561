import { useContext, useState } from "react";
import { AppContext, MapContext } from "../context";
import MapBoxWrapper from "./MapBoxWrapper";
import Icon from "../Icon";
import { cn, Header } from "../helpers"; 

import MapGenerator from "@watergis/mapbox-gl-export/dist/map-generator";
import "../../../node_modules/@watergis/mapbox-gl-export/css/styles.css";

// eslint-disable-next-line import/no-webpack-loader-syntax
import { accessToken, Map as MapboxMap } from '!mapbox-gl';

import html2canvas from "html2canvas";
import { FieldControl, LevelControl, LocationControl } from "../controls";

// override @watergis map generator in order to add legend to the image
MapGenerator.prototype.generate = function( onDone = () => {} ){
  const this_ = this

  // Calculate pixel ratio
  const actualPixelRatio = window.devicePixelRatio
  Object.defineProperty(window, "devicePixelRatio", {
    get() {
      return this_.dpi / 96
    }
  })
  // Create map container
  const hidden = document.createElement("div")
  hidden.className = "hidden-map"
  document.body.appendChild(hidden)
  const container = document.createElement("div")

  const mapBoundingBox = this.map.getContainer().getBoundingClientRect();

  let zoom = Math.round( this.map.getZoom() ),
      maxZoom = this.map.getMaxZoom();
  
  // https://docs.mapbox.com/help/glossary/zoom-level/
  const zoomLevels = [ 59959.436, 29979.718, 14989.859, 7494.929, 3747.465, 1873.732, 936.866, 468.433, 234.217, 117.108, 58.554, 29.277, 14.639, 7.319, 3.660, 1.830, 0.915, 0.457, 0.229, 0.114, 0.057, 0.029, 0.014 ];

  let scale = zoomLevels[ zoom ] / zoomLevels[ maxZoom ];
  if( mapBoundingBox.width * scale > 4096 || mapBoundingBox.height * scale > 4096 ) {
    scale = Math.min( 4096 / mapBoundingBox.width, 4096 / mapBoundingBox.height );
  }

  if( scale > 3 ) {
    scale = 3;
  }
  
  container.style.width = mapBoundingBox.width * scale + 'px'; // this.toPixels( this.width )
  container.style.height =  mapBoundingBox.height * scale + 'px'; // this.toPixels( this.height )
  hidden.appendChild(container)
  
  const style = this.map.getStyle()
  if (style && style.sources) {
    const sources = style.sources
    Object.keys(sources).forEach(name => {
      const src = sources[name]
      Object.keys(src).forEach(key => {
        // delete properties if value is undefined.
        // for instance, raster-dem might has undefined value in "url" and "bounds"
        if (!src[key]) delete src[key]
      })
    })
  }
  
  // Render map
  const renderMap = new MapboxMap({
    accessToken: this.accesstoken || accessToken,
    container,
    style,
    center: this.map.getCenter(),
    zoom: this.map.getZoom(),
    bearing: this.map.getBearing(),
    pitch: this.map.getPitch(),
    interactive: false,
    preserveDrawingBuffer: true,
    fadeDuration: 0,
    attributionControl: false,
    // hack to read transfrom request callback function
    transformRequest: this.map._requestManager._transformRequestFn
  })

  renderMap.fitBounds( this.map.getBounds() );
  
  // @ts-ignore
  const images = (this.map.style.imageManager || {}).images || []
  Object.keys(images).forEach(key => {
    renderMap.addImage(key, images[key].data)
  })
  
  renderMap.once("idle", () => {
    const canvas = renderMap.getCanvas()

    const img = new Image();
    img.onload = () => {
      html2canvas( document.getElementById('map-legend'), { backgroundColor: '#FFFFFF', scale } )
      .then( legendCanvas => {
        const { width, height } = canvas.getBoundingClientRect();
        const newCanvas = document.createElement('canvas');
        newCanvas.setAttribute('width', width);
        newCanvas.setAttribute('height', height);

        const ctx = newCanvas.getContext('2d');
        ctx.drawImage( img, 0, 0, width, height );

        const sw = legendCanvas.width, 
              sh = legendCanvas.height;

        ctx.drawImage( legendCanvas, width - sw - 5, height - sh - 5, sw, sh );

        const fileName = `mcje-map.${this_.format}`;
        this_.toPNG(newCanvas, fileName);
      
        renderMap.remove()
        hidden.parentNode?.removeChild(hidden)
        Object.defineProperty(window, "devicePixelRatio", {
          get() {
            return actualPixelRatio
          }
        });

        onDone();
      });
      
    };
    img.src = canvas.toDataURL();    

  });
}

const Container = ({
    mapWidth,
  }) => {
  // indicate map export process
  const [doingMapExport,setDoingMapExport] = useState( false );

  // mapbox object, is used for map export / download
  const [mapContainer,setMapContainer] = useState( null ); 

  const {
    isResizing,
    } = useContext( AppContext );

  const {
      dispatch: mapDispatch,
      mask,
      } = useContext( MapContext ); 
  
  function exportMap(){
    setDoingMapExport( true );

    const mapGenerator = new MapGenerator(
      mapContainer,
      [297, 210],//pageSizeValue,
      300, // Number(dpiType.value),
      'png', //formatType.value,
      'mm', //Unit.mm,
      process.env.REACT_APP_MAPBOX_API_KEY // this.options.accessToken,
    );

    mapGenerator.generate( () => setDoingMapExport(false) );
  } // exportMap  

  return (
    <div className="container map-container" 
        style={{ 
          width: `calc( ${mapWidth}% - 1rem - 0.5px )`, 
          overflowX: isResizing ? 'hidden' : null 
        }}>
      <div className="flex justify-between">
        <Header>Map</Header>

        { false && 
        <button className="flex gap-2 items-center text-xs leading-4 font-semibold"
          onClick={ () => exportMap() }
          >
          { doingMapExport ? <span className="animate-spin"><Icon name='hourglass-half' /></span> : <Icon name='upload' /> }
          <span>Export</span>
        </button> }
      </div>

      <div className="flex flex-wrap justify-between items-center gap-2 text-xs leading-4 font-semibold">
        <div className="flex items-center gap-2">
          <span>Data:</span>
          
          <div className="flex items-center gap-2 flex-wrap">
            <FieldControl />

            <LevelControl />
          </div>
        </div>

        <div className="flex items-center gap-2">
          <span>Location: </span>
          
          <LocationControl />
        </div>

        <div>
          <button onClick={ () => {
              mapDispatch({ type: 'set-mask', value: !mask });
              localStorage.setItem('maskLocation', !mask )
            }}
            className={ cn({ 'flex items-center gap-2': true, 'text-theme-blue': mask }) }
            >
            <Icon name={ mask ? 'square-check' : 'square' } />

            <span className="text-left leading-none">Mask location</span>
          </button>
        </div>
      </div>

      
      <div className="flex-auto"
        onMouseLeave={() => {
          mapDispatch({ type: 'set-fips', value: null });
        }}>
        { !isResizing && <>
          { 
            <MapBoxWrapper 
                setMap={ setMapContainer }
                mapHeight={ '100%' }
                /> 
          }
          </>
        }
      </div>


    </div>    
  )
}

export default Container;