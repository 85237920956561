import { useContext, useRef, useState } from "react";
import MapContainer from "./maps/MapContainer";
import ChartsContainer from "./charts/ChartsContainer";
import Icon from "./Icon";

import { AppContext, MapContext } from "./context";

const MapChartsContainer = ({ width, map, charts, table }) => {
  const {
    } = useContext( AppContext );
  
  const {
    dispatch: mapDispatch,
    reload: reloadMap    
  } = useContext( MapContext );

  const resizeContainer = useRef(null);
  const resizer = useRef(null);

  const [ isResizing, setIsResizing ] = useState( false );
  const [ mapWidth, setMapWidth ] = useState( 50 );
  const [ chartsWidth, setChartsWidth ] = useState( 50 );


  function resize(event){
    if( !isResizing || !resizer.current || !resizeContainer.current ) {
      return;
    }

    const rw = resizer.current.getBoundingClientRect().width,
          rc = resizeContainer.current.getBoundingClientRect(),
          x = event.clientX - rc.left,
          w = rc.width;

    setMapWidth( x / w * 100 );
    setChartsWidth( ( w - x ) / w * 100 );   
  } // resize

  function startResizing(){
    setIsResizing( true )
  }

  function endResizing(){
    setIsResizing( false );
    mapDispatch({ type: 'set-reload', value: reloadMap + 1 });
  } 

  return (
    <div ref={resizeContainer}
          className="relative inline-block h-full" 
          style={{ width: `calc( ${table ? width : 100}% - 3rem )` }}
          onMouseMove={ resize }  
          onMouseUp={ endResizing }
          >
      
      { map && <MapContainer mapWidth={ charts ? mapWidth : 100 } /> }

      { 
        map && charts && (
        <div className="resizer" ref={resizer}>
          <div className="--indicator"></div>

          <button onMouseDown={ startResizing }>
            <Icon name='arrows-left-right' /> 
          </button>
        </div>
        )
      }

      { charts && <ChartsContainer width={ map ? chartsWidth : 100 } /> } 
    </div>
  );
}


export default MapChartsContainer;