import { useContext } from "react";
import { AppContext, MapContext } from "./context";

export const FieldControl = () => {
  const { allTableFields, fields } = useContext( AppContext );
  const { field: mapField, updateMapField } = useContext( MapContext );

  return (
    <select value={ mapField } 
            onChange={ (event) => updateMapField( event.target.value ) }>
      { 
      allTableFields?.map( ( f ) => {
        const k = typeof f === 'object' ? f.name : f,
              field = fields.find( field => field.name === k );
        
        if( ['fipsCode','fipsLabel'].includes(k) ) {
          return null;
        }

        return <option value={ k } key={ k }>{ ( field && ( field.label || field.name ) ) || k }</option>
      }) 
      }
    </select>
  );
}

export const LevelControl = () => {
  const { level, levels, changeLevel } = useContext( AppContext );

  const levelsOptions = () => {
    const options = [];
    for( let k in levels ) {
      options.push( <option key={k} value={k}>{ levels[k] }</option> );
    }
    return options;
  };

  return (
    <select value={level} 
          onChange={ (event) => changeLevel( event.target.value ) }>
      { levelsOptions() }
    </select>
  );
};


export const LocationControl = () => {
  const { location, locationHistory, changeLocation, counties, places } = useContext( AppContext );
  const { dispatch: mapDispatch } = useContext( MapContext );

  return (
    <select value={location} 
      onChange={ (event) => {
        mapDispatch({ type: 'set-recenter', value: true });
        mapDispatch({ type: 'set-mask', value: true });
        changeLocation( event.target.value );
      }
    }>
      <option value="">Michigan</option>
      { locationHistory.map( (fips,index) => (
        <option value={ fips } key={ index }>
          { [...counties,...places].find( l => l.code === fips )?.label ?? fips }
        </option>
        ) 
      )}
    </select>
  )
}