import Icon from "./Icon";
import { cn } from "./helpers";
import { AppContext, MapContext } from "./context";
import { useContext } from "react";

const IndexVariableManager = () => {
  const {
    indexVariables,
    setIndexVariables,

    idxVar,
    setIdxVar,

    fields,
    setFields,

    categories,
    setCategories,

    columns,
    toggleColumn,
  } = useContext( AppContext );

  const { dispatch: mapDispatch } = useContext( MapContext )


  function removeIdxVar( key ) {
    const newIdxVar = JSON.parse( JSON.stringify(idxVar) );

    const idx = newIdxVar.vars.findIndex( f => f.name == key );
    newIdxVar.vars.splice( idx, 1 );

    setIdxVar( newIdxVar );
  }

  function updateIdxVar( variable){
    const newIdxVar = JSON.parse( JSON.stringify(idxVar) );

    const idx = newIdxVar.vars.findIndex( f => f.name == variable.name );
    newIdxVar.vars.splice( idx, 1, variable );

    setIdxVar( newIdxVar );
  }

  function onApply(){
    const newIndexVariables = JSON.parse( JSON.stringify( indexVariables ) );
    const newCategories = JSON.parse( JSON.stringify( categories ) );                          
    const newIdxVar = JSON.parse( JSON.stringify( idxVar ) );

    // add new fields as a category
    if( !newIdxVar.name ) {
      newIdxVar.name = '_idx_' + (+new Date());

      newIndexVariables.push( newIdxVar );

      newCategories.push({
        id: +(new Date()),
        icon: null,
        label: newIdxVar.label,
        name: newIdxVar.name,
        parent: 999999
      });
    }
    else {
      // update category
      const idx = newIndexVariables.findIndex( f => f.name == newIdxVar.name );
      newIndexVariables.splice( idx, 1, newIdxVar );

      const catIdx = newCategories.findIndex( c => c.name == newIdxVar.name || c.name == 'cat_' + newIdxVar.name );
      if( catIdx === -1 || !newCategories[ catIdx ] ) {
        newCategories.push({
          id: +(new Date()),
          icon: null,
          label: newIdxVar.label,
          name: newIdxVar.name,
          parent: 999999
        });
      }
      else {
        newCategories[ catIdx ].label = newIdxVar.label;
      }
    }

    setIndexVariables( newIndexVariables );
    setCategories( newCategories );

    localStorage.setItem('indexVariables', JSON.stringify(newIndexVariables) );

    // add or update record in allFields
    const newFields = JSON.parse( JSON.stringify( fields ) ),
      existingField = newFields.find( f => f.name == newIdxVar.name );

    if( existingField ) {
      if( existingField.label != newIdxVar.label ) {
        existingField.label = newIdxVar.label;
        setFields( newFields );
      }
    }
    else {
      // new field, we should add it to the fields
      const maxFieldId = newFields.reduce( (prev,cur) => {
        if( prev && prev.id > cur.id ) {
          return prev.id;
        }
        return cur.id;
      });

      newFields.push({
        id: maxFieldId + 1,
        category_id: newCategories.find( c => c.name == 'cat_' + newIdxVar.name )?.id,
        label: newIdxVar.label,
        name: newIdxVar.name,
        step: 0.01
      });

      setFields( newFields );
    }

    // add new index variable to selected columns
    if( !columns.includes( newIdxVar.name ) ) {
      toggleColumn( newIdxVar.name );
      mapDispatch({ type: 'set-field', value: newIdxVar.name });
    }    

    // close popup
    setIdxVar( null );                          
  } // onApply

  return (
    <div className={ cn( "relative" ) }>
      <button className="w-full flex justify-between button pl-2 pr-3 pt-[11px] pb-2 rounded-lg bg-theme-grey 
                        text-theme-blue text-xs font-semibold leading-tight"
        onClick={ () => {
          setIdxVar({
            id: +(new Date),
            label: "",
            vars: []
          });
        } }>
        <span>Create Index Variable</span>
        <span><Icon name="plus" /></span>
      </button>

      { idxVar && 
        <div className="fixed z-10 left-72 bottom-4 bg-white p-4 rounded border border-gray-600 shadow-sm
          flex flex-col gap-6">
          <ol className="bg-gray-200 text-gray-800 list-decimal
            rounded py-3 px-6">
            <li>Select Variable From The Left</li>
            <li>Select if High or Low values are Of intereset</li>
            <li>Assign Weights to each Variable</li>
            <li>Give the variable a descriptive name</li>
          </ol>

          <table>
            <thead>
              <tr>
                <th className="min-w-[10rem]">Variable Name</th>
                <th className="px-2">High/Low</th>
                <th className="px-2">Weight</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="leading-8">
              { idxVar.vars.map( (v,i) => ( 
                  <tr key={i}>
                    <td>{ fields.find( f => f.name == v.name ).label }</td>
                    <td>
                      <select 
                        value={ v.dir }
                        onChange={ (e) => updateIdxVar( {...v, dir: e.target.value } ) }>
                        <option value="high">High</option>
                        <option value="low">Low</option>
                      </select>
                    </td>

                    <td>
                      <input type="text"
                        className="w-12 text-center"
                        value={ v.weight }
                        onChange={ (e) => updateIdxVar( {...v, weight: e.target.value } ) } />
                    </td>

                    <td>
                      <button className="text-xs text-gray-400"
                        onClick={ () => removeIdxVar( v.name ) }>
                        <Icon name="xmark" />
                      </button>                                
                    </td>
                  </tr>
                ) 
              )}

            </tbody>
          </table>

          <div>
            <div>Name</div>
            <input 
              type="text"
              className="w-full px-1"
              required={true}
              value={ idxVar.label } 
              onChange={ (e) => {
                  const newIdxVar = JSON.parse( JSON.stringify(idxVar) );
                  newIdxVar.label = e.target.value;
                  setIdxVar( newIdxVar );
                } 
              } />
          </div>

          <div className="flex gap-8 justify-between">
            <div>
              { idxVar?.name && 
                  <button className="button button-danger"
                    onClick={ () => {
                      const newIndexVariables = JSON.parse( JSON.stringify( indexVariables ) );
                      const newCategories = JSON.parse( JSON.stringify( categories ) );                          
                      
                      const varIdx = newIndexVariables.findIndex( v => v.name == idxVar.name );
                      const catIdx = newCategories.findIndex( c => c.name == ( 'cat_' + idxVar.name) );

                      newIndexVariables.splice( varIdx, 1 );
                      newCategories.splice( catIdx, 1 );

                      if( columns.includes( idxVar.name ) ) {
                        toggleColumn( idxVar.name );

                        // add or update record in allFields
                        const newFields = JSON.parse( JSON.stringify( fields ) ),
                              fieldIdx = newFields.findIndex( f => f.name == idxVar.name );

                        newFields.splice( fieldIdx, 1 );

                        setFields( newFields );

                        if( newFields.length ) {
                          mapDispatch({ type: 'set-field', value: newFields[0].name });
                        }
                      }

                      setIndexVariables( newIndexVariables );
                      setCategories( newCategories );
                      localStorage.setItem('indexVariables', JSON.stringify(newIndexVariables) );

                      // close popup
                      setIdxVar( null );                                  
                    }}>
                    Delete
                  </button>
              }
            </div>

            <div className="flex gap-2">
              <button className="button" onClick={ () => setIdxVar( null ) }>Cancel</button>

              <button className="button button-primary" onClick={ onApply }>Apply</button>
            </div>
          </div>                    
        </div> 
      }
    </div>
  )
}

export default IndexVariableManager;