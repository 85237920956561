import React, { useState, useRef, useEffect, useContext } from "react";
import { init, getInstanceByDom, registerTransform } from "echarts";
import { transform } from "echarts-stat";
import { AppContext, MapContext } from "../context";

function ReactECharts({
  option,
  style,
  settings,
  loading,
  theme,
  width,
  reload
}) {
  const { lockedFips, setLockedFips } = useContext( AppContext );
  const [ hasListeners, setHasListeners ] = useState( false );
  const chartRef = useRef(null);

  useEffect(() => {
    registerTransform( transform.histogram );
    registerTransform( transform.regression );
    
    // Initialize chart
    let chart;
    if (chartRef.current !== null) {
      chart = init(chartRef.current, theme);
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener("resize", resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, [theme]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart.setOption(option, settings);

      chart.on('click', function( info ){
        if( info?.data?.fips && info?.data?.fips ) {
          const list = [...lockedFips];
          if( list.includes( info.data.fips ) ) {
            list.splice( list.indexOf( info.data.fips ), 1 );
          }
          else {
            list.push( info.data.fips );
          }
          setLockedFips( list );
        }
      });

    }
  }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart.setOption(option, { ...settings, notMerge: true });
    }
  }, [reload]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      loading === true ? chart.showLoading() : chart.hideLoading();
    }
  }, [loading, theme]);

  useEffect( () => { window.dispatchEvent( new Event('resize') ) }, [width]);

  return <div ref={chartRef} style={{ width: "100%", height: "100px", ...style }} />;
}

export default ReactECharts;