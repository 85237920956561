
import React, { useState, useRef, useContext } from "react";
import HistogramChart from "./Histogram";
import ScatterChart from "./Scatter";
import { ChartsContext, MapContext } from "../context";

import { Header } from "../helpers";
import Icon from "../Icon";
import { LevelControl, LocationControl } from "../controls";



function Charts({ width }) {
  const { charts, dispatch } = useContext( ChartsContext );

  // const [charts,setCharts] = useState( JSON.parse( localStorage?.getItem('charts') ?? '[]' ) );
  const container = useRef(null);
  const sidebar = useRef(null);

  const containerWidth = container?.current?.getBoundingClientRect()?.width ?? 0;
  const chartsSectionWidth = containerWidth - sidebar?.current?.getBoundingClientRect()?.width ?? 0 - 16;

  return (
    <div ref={container}
          className="container charts-container" 
          style={{ 
            width: width < 100 ? `calc( ${width}% - 1rem - 1px)` : `100%`,
          }}>

      <Header>Charts</Header>

      <div className="flex flex-wrap items-center gap-2 text-xs leading-4 font-semibold">
        <span>Location: </span>
        <LocationControl />
        <LevelControl />
      </div>

      <div className="flex gap-4 my-3 text-xs leading-4 font-semibold
                      items-start">
        <div ref={sidebar} className="flex flex-col gap-4">
          <button className="flex flex-col gap-1 items-center"
            onClick={(e) => {
              e.stopPropagation();
              dispatch({ type: 'add', chartType: 'histogram'});
            }}>
            <span className="text-5xl text-[#1a431a]"><Icon name="histogram" /></span>
            <span>+ Histogram</span>
          </button>
          
          <button className="flex flex-col gap-1 items-center"
            onClick={(e) => {
              e.stopPropagation();
              dispatch({ type: 'add', chartType: 'scatter'});
            }}>
            <span className="text-5xl text-[#173276]"><Icon name="scatter" /></span>
            <span>+ Scatter Plot</span>              
          </button>
        </div>

        <div className="flex flex-col gap-6 scrollbar overflow-y-auto" 
              style={{ 
                width: chartsSectionWidth + 'px',
                height: `calc( 100vh - ${sidebar?.current?.getBoundingClientRect().top}px - 2rem)`
              }}>

          { charts?.map( (chart,index) => {
            switch( chart.type ) {
              case "histogram":
                return <HistogramChart key={index} chart={chart} width={ chartsSectionWidth  } />;
              case "scatter":
                return <ScatterChart key={index} chart={chart} width={ chartsSectionWidth } />;
              default:
                <div key={index}></div>
            } 

            return <div key={index}></div>;
          })}
        </div>
      </div>
    </div>
  )
}

export default Charts